import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthAPIService, AppService, GoogleAnalyticsService } from '@fengbrasil/ngx-fengst-auth';
import { LoadingService } from '@fengbrasil/ngx-fengst-ui';
import { ResizeService } from '@fengbrasil/ngx-fengst-ui';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private subsink = new SubSink();

  public isHome: Observable<boolean> = this.appService.isHome$;
  public isDependent: Observable<boolean> = this.appService.isDependent$;
  public isAuth: Observable<boolean> = this.authService.isAuth$['st'];
  public isAuthAdmin: Observable<boolean> = this.authService.isAuth$['admin'];
  public isAuthStaging: Observable<boolean> = this.authService.isAuth$['staging'];
  public isResale: Observable<boolean> = this.authService.isAuth$['resale'];
  public isLoading: Observable<boolean> = this.loadingService.isLoading$;

  title = 'ngx-feng';

  constructor(
    private resizeService: ResizeService,
    private loadingService: LoadingService,
    private authService: AuthAPIService,
    private appService: AppService,
    private googleAnalyticsService:GoogleAnalyticsService,
    private router: Router,
    @Inject('fengEnv') public fengEnv: any,
    @Inject('fengSettings') public fengSettings: any
  ) {
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;
    this.resizeService.setSize(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.resizeService.setSize(window.innerWidth);
    
    this.subsink.add(
      this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        window.scrollTo(0, 0);
        this.appService.mainChecks(event.url);
        })
      )
  }

  onRouterOutletActivate(_event: any) {
    this.authService.getIdpessoa().then((idpessoa: string|null) => {
      // https://metricasboss.notion.site/Guia-de-implementa-o-da-camada-de-dados-54e3f6df0f5a4c5494b84fec535f2841
      const data: Record<string, unknown> = {
        event: 'virtual_page_view',
      };

      if (idpessoa) data.user_id = idpessoa;

      this.googleAnalyticsService.pushDataLayer(data);
    });
  }

  redirect() {
    window.open('https://api.whatsapp.com/send/?phone=5521972552137&text&type=phone_number&app_absent=0', '_blank');
  }
  
}