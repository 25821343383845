import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { fengEnv } from './env';

const qs = window.location.search;
if (qs.indexOf("code=") > -1) {
  console.info(
    "Updated querystring to avoid the xero code quirk",
    window.location.search
  );
  window.location.search = qs.replace("code", "cog");
}

if (fengEnv.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
