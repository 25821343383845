import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Theme & Settings
import { fengTheme } from '../theme';
import { fengSettings } from '../settings';
import { fengEnv } from '../env';

// FengST
import { NgxFengSTUiModule } from '@fengbrasil/ngx-fengst-ui';
import { NgxFengSTLayoutModule } from '@fengbrasil/ngx-fengst-layout';
import { NgxFengSTContactModule } from '@fengbrasil/ngx-fengst-contact';
import { JwtInterceptor, NgxFengSTAuthModule } from '@fengbrasil/ngx-fengst-auth';
import { NgxFengSTAccountModule } from '@fengbrasil/ngx-fengst-account';
import { NgxFengstExclusiveContentModule } from '@fengbrasil/ngx-fengst-exclusive-content';
import { NgxFengSTXRayModule } from '@fengbrasil/ngx-fengst-xray';
import { NgxFengstEventsModule } from '@fengbrasil/ngx-fengst-events';
import { NgxFengSTPartnerModule } from '@fengbrasil/ngx-fengst-partner';
import {NgxFengstEmbassiesModule} from '@fengbrasil/ngx-fengst-embassies'
import {NgxFengstLandingModule} from '@fengbrasil/ngx-fengst-landing'
import { EmbedFormsComponent } from './embed-forms/embed-forms.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
      region: fengEnv?.auth?.cognito?.REGION,
      userPoolId: fengEnv?.auth?.cognito?.USER_POOL_ID,
      userPoolWebClientId: fengEnv?.auth?.cognito?.APP_CLIENT_ID,
      
      signUpVerificationMethod: 'code',
      authenticationFlowType: 'USER_SRP_AUTH',

      oauth: {
          domain: fengEnv?.auth?.cognito?.FRONT_URL,
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: fengEnv?.auth?.cognito?.FRONT_URL,
          redirectSignOut: fengEnv?.auth?.cognito?.FRONT_URL,
          responseType: 'token'
      }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    EmbedFormsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,

    NgxFengSTUiModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTAuthModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTLayoutModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTAccountModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstExclusiveContentModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTPartnerModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTContactModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTXRayModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstEventsModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstEmbassiesModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstLandingModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv})
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: fengEnv.recaptcha.key
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor, 
      multi: true 
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
