import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-embed-forms',
  templateUrl: './embed-forms.component.html',
  styleUrls: ['./embed-forms.component.scss']
})
export class EmbedFormsComponent implements OnInit {

  public formSrc: SafeResourceUrl = '';

  constructor(public route: ActivatedRoute,
              private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.formSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('https://forms.office.com/Pages/ResponsePage.aspx?id=xYQXFMt3k0-DEgyIeVLI41gsP9-ZJR1Oo2bWZrsEi4pUQk5JUzg2TldBV1IwWkZQTVlZRlFHWFNJUy4u&embed=true');
  }

}
